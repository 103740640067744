import classNames from "classnames";

import UsePriceCountdown from "~/components/videoProductPage/common/usePriceCountdown";

import * as styles from "./CollectionAvailableBlock.css";

const CollectionAvailableBlock = ({
  availableUntil,
  mobileView,
  contentStyle,
}: {
  availableUntil: string;
  mobileView?: boolean;
  contentStyle?: string;
}) => {
  const collectionAvailableUntil = new Date(availableUntil);
  const todayDate = new Date();
  const isCollectionAvailable = collectionAvailableUntil.getTime() - todayDate.getTime() > 0;
  // const isCollectionAvailable = false;
  const { days, hours, minutes, seconds } = UsePriceCountdown({
    targetDate: availableUntil,
  });
  const displayDate = new Date(collectionAvailableUntil.getTime() - 24 * 60 * 60 * 1000);

  return (
    <>
      {isCollectionAvailable ? (
        <div className={styles.offerInfo}>
          <div className={styles.offerBlock}>
            <span className={classNames(contentStyle, styles.offerInfoText)}>
              {!mobileView ? `Collection available` : `Available`} until{" "}
              {displayDate.toLocaleDateString("en-us", {
                month: "long",
                day: "numeric",
              })}
              {": "}
            </span>
            <span className={classNames(contentStyle, styles.counter)}>
              {days ? ` ${days}d` : "0d"}
              {hours ? ` ${hours}h` : " 0h"}
              {minutes ? ` ${minutes}m` : " 0m"}
              {seconds ? ` ${seconds}s` : " 0s"}
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CollectionAvailableBlock;
