import React, { useState, useEffect } from "react";

const UsePriceCountdown = ({ targetDate }: { targetDate: string | undefined }) => {
  if (!targetDate) {
    let dateInNineDays = new Date();
    dateInNineDays.setDate(dateInNineDays.getDate() + 9);
    targetDate = dateInNineDays.toISOString();
  }
  const [timeLeft, setTimeLeft] = useState(() => {
    const end = new Date(targetDate ?? "").getTime();
    const now = new Date().getTime();
    return end - now;
  });

  const [expired, setExpired] = useState(timeLeft < 0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = prevTimeLeft - 1000;
        if (newTimeLeft < 0) {
          setExpired(true);
          clearInterval(intervalId);
        }
        return newTimeLeft;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function convertToString(time: number) {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    time -= days * (1000 * 60 * 60 * 24);
    const hours = Math.floor(time / (1000 * 60 * 60));
    time -= hours * (1000 * 60 * 60);
    const minutes = Math.floor(time / (1000 * 60));
    time -= minutes * (1000 * 60);
    const seconds = Math.floor(time / 1000);
    return { days, hours, minutes, seconds };
  }

  const { days, hours, minutes, seconds } = convertToString(timeLeft);
  const priceClosesCountdownText = `${days}d, ${hours}h, ${minutes}m, ${seconds}s`;

  return {
    days,
    hours,
    minutes,
    seconds,
    expired,
    timeLeft,
    priceClosesCountdownText,
  };
};

export default UsePriceCountdown;
